<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <div class="error-page d-lg-flex align-items-center">
      <div class="img-holder order-lg-last">
        <img
          src="../assets/images/assets/404lost.png"
          alt=""
          class="w-100 illustration"
        >
      </div>
      <div class="text-wrapper order-lg-first">
        <div class="logo" />
        <h1 class="font-slab">
          Sorry, <br>The Page Can’t <br>be Found.
        </h1>
        <p class="font-gordita">
          No worries, it happens.
        </p>
        <a
          href="/"
          class="theme-btn-home"
        >Back to home</a>
      </div> <!-- /.text-wrapper -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
<style scoped>
@import 'css/custom.css';
@import 'css/style.css';
@import 'css/custom-animation.css';
@import 'css/framework.css';
@import 'css/menu.css';
@import 'css/responsive.css';

</style>
